import React from 'react'
import Container from '../components/Layout/Container'

const NotFoundPage = () => (
  <Container>
    <h1>404</h1>
    <p>This route doesn't exist yet.</p>
  </Container>
)

export default NotFoundPage
